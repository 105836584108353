<script>
/**
 * Device logs component
 */
import moment from "moment";
import { logsMethods, authComputed } from "@/state/helpers";

export default {
  props: ["device", "logs", "busy"],
  components: {},
  data() {
    return {
      logsList: [],

      dates: [],
      fromTime: "00:00",
      toTime: "00:00",
      fromContext: null,
      toContext: null,
      minDate: "2000-01-01",
      menu: false,

      exportStatus: false,
    };
  },
  computed: {
    ...authComputed,
    dateRangeText() {
      if (this.dates.length > 0) {
        return this.dates.join(" ~ ");
      } else {
        return "Export XLS !";
      }
    },
    fieldsComputed() {
      return [
        {
          key: "date",
          sortable: false,
          label: this.$t("dataTable.dateTime.text"),
        },
        {
          key: "alertedAt",
          sortable: false,
          label: this.$t("dataTable.dateAlert.text"),
        },
        { key: "user", sortable: false, label: this.$t("dataTable.user.text") },
        {
          key: "state",
          sortable: false,
          label: this.$t("dataTable.status.text"),
        },
        {
          key: "action",
          sortable: false,
          label: this.$t("dataTable.action.text"),
        },
        {
          key: "cause",
          sortable: false,
          label: this.$t("dataTable.cause.text"),
        },
        {
          key: "value",
          sortable: false,
          label: this.$t("dataTable.value.text"),
        },
        {
          key: "savedDoses",
          sortable: false,
          label: this.$t("dataTable.savedDoses.text"),
        },
        { key: "note", sortable: false, label: this.$t("dataTable.note.text") },
      ];
    },
  },
  mounted() {},
  methods: {
    ...logsMethods,
    async exportData() {
      if (this.dates.length == 2) {
        let from = `${this.dates[0]} ${this.fromTime}`;
        let to = `${this.dates[1]} ${this.toTime}`;
        let params = {
          serial: this.device.serial,
          from: from,
          to: to,
          populate: "region,province,user,action,cause,state",
          sortBy: "createdAt:desc",
        };
        if (this.dates[0] == this.dates[1]) {
          let fromHoure = this.fromContext.hours;
          let fromMinute = this.fromContext.minutes;
          let toHoure = this.toContext.hours;
          let toMinute = this.toContext.minutes;
          if (toHoure >= fromHoure) {
            if (
              toHoure > fromHoure ||
              (toHoure == fromHoure && toMinute >= fromMinute)
            ) {
              this.$refs.menu.save(this.dates);
              this.exportStatus = true;
              await this.exportXLS(params)
                .then((res) => {
                  if (res.status) this.exportStatus = false;
                  else {
                    this.exportStatus = false;
                    this.makeToast(
                      "Export",
                      this.$t("msgError.exportEmpty.text"),
                      "danger"
                    );
                  }
                })
                .catch(() => {
                  this.exportStatus = false;
                });
              this.dates = [];
              this.$refs.menu.save(this.dates);
            } else {
              this.makeToast(
                "Date",
                this.$t("msgError.periodDate.text"),
                "danger"
              );
            }
          } else {
            this.makeToast(
              "Date",
              this.$t("msgError.periodDate.text"),
              "danger"
            );
          }
        } else {
          this.$refs.menu.save(this.dates);
          await this.exportXLS(params)
            .then((res) => {
              if (res.status) this.exportStatus = false;
              else {
                this.exportStatus = false;
                this.makeToast(
                  "Export",
                  this.$t("msgError.exportEmpty.text"),
                  "danger"
                );
              }
            })
            .catch(() => {
              this.exportStatus = false;
            });
          this.dates = [];
          this.$refs.menu.save(this.dates);
        }
      }
    },
    onFromContext(ctx) {
      this.fromContext = ctx;
    },
    onToContext(ctx) {
      this.toContext = ctx;
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  watch: {
    logs(newVal) {
      let logsListCustom = newVal.map((obj) => {
        let savedDosesFiltered = obj.savedDoses.map(obj=>{
          return `${obj.vaccin}: <b>${obj.number}</b>`
        })
        return {
          ...obj,
          user: obj.user ? obj.user.fullName : "-",
          state: obj.state ? obj.state.name : "-",
          action: obj.action ? obj.action.name : "-",
          cause: obj.cause ? obj.cause.name : "-",
          savedDoses: savedDosesFiltered.join('<br />'),
          date: moment(obj.createdAt).format("DD-MM-YYYY HH:mm:ss"),
          alertedAt: moment(obj.alertedAt).format("DD-MM-YYYY HH:mm:ss"),
        };
      });
      this.logsList = logsListCustom;
    },
    dates(newVal) {
      if (newVal.length == 1) {
        this.minDate = newVal[0];
      } else {
        this.minDate = "2000-01-01";
      }
    },
  },
};
</script>

<template>
  <div
    class="table-responsive mb-3 bg-white rounded-lg"
    style="min-height: 414px"
  >
    <div class="row">
      <div class="col-8">
        <h5 class="m-3">{{ $t("titles.logs.text") }}</h5>
      </div>
      <div v-if="exportStatus" class="col-4">
        <div
          class="d-flex justify-content-center align-items-center mt-2"
          style="position: relative; z-index: 200"
        >
          <b-spinner label="Loading..." variant="primary"></b-spinner>
        </div>
      </div>
      <div v-if="!exportStatus" class="col-4" style="z-index: 1000" data-app>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            no-title
            range
            scrollable
            color="#e52342"
            :min="minDate"
          >
            <div class="row">
              <div class="col-6 d-flex">
                <!--  {{ $t("modals.fromTime.text") }} -->
                <b-time
                  hide-header
                  locale="fr"
                  v-model="fromTime"
                  style="font-size: 4px"
                  @context="onFromContext"
                ></b-time>
              </div>
              <div class="col-6 d-flex">
                <!-- {{ $t("modals.toTime.text") }} -->
                <b-time
                  hide-header
                  locale="fr"
                  v-model="toTime"
                  style="font-size: 4px"
                  @context="onToContext"
                ></b-time>
              </div>
              <div class="col-12 text-left">
                <b-button class="mb-1" variant="success" @click="exportData()">
                  {{ $t("buttons.exportXLS.text") }}
                </b-button>
              </div>
            </div>
          </v-date-picker>
        </v-menu>
      </div>
      <!--<div class="col-6">

        <b-button class="mt-2" variant="success" @click="openExport()">{{
          $t("buttons.exportXLS.text")
        }}</b-button>
         <b-modal
          id="export-logs-modal"
          ref="export-logs-modal"
          size="md"
          @hidden="cancel"
          :title="this.$t('buttons.exportXLS.text')"
        >
          <div class="row">
            <div class="col-8"></div>
            <div class="col-4"></div>
          </div>
        </b-modal> 
      </div>-->
    </div>
    <b-table
      class="mb-0"
      hover
      show-empty
      centred
      :busy="busy"
      :empty-text="this.$t('dataTable.noLogs.text')"
      :items="logsList"
      :fields="fieldsComputed"
      stacked="sm"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <strong>{{ $t("dataTable.loading.text") }}</strong>
        </div>
      </template>
      <template v-slot:cell(state)="row">
        <div
          class="badge font-size-12"
          :class="{
            'badge-soft-warning': `${row.value}` === 'NEW',
            'badge-soft-info': `${row.value}` === 'OPENED',
            'badge-soft-secondary': `${row.value}` === 'SUSPENDED',
            'badge-soft-danger': `${row.value}` === 'CLOSED',
            'badge-soft-dark': `${row.value}` === 'IGNORED',
          }"
        >
          {{ row.value }}
        </div>
      </template>
      <template v-slot:cell(savedDoses)="data">
        <span v-html="data.value"></span>
      </template>
      <template v-slot:cell(value)="row">
        <div
          class="font-size-14 text-center font-weight-bold"
          :class="{
            'text-danger': `${row.value}` > 8,
            'text-primary': `${row.value}` < 2,
          }"
        >
          {{ row.value }}
        </div>
      </template>
    </b-table>
  </div>
</template>